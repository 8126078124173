import React from "react"
import HomeLayout from "../layouts/HomeLayout"
import { graphql } from "gatsby"
import SEO from "../components/Seo"
import SectionTitle from "../components/SectionTitle"

const FirmaPage = ({data}) => (
  <>
    <SEO title="O nas - Usługi Pogrzebowe ABRAM Ostrów Wielkopolski" />
    <HomeLayout data={data}>
        <div id="firma" className="firma-page">
            <div className="container">
                <div className="content page-content">
                    <SectionTitle title="O firmie" bottom={false} />
                    <p>
                    Naszą misją jest <strong>świadczenie profesjonalnych usług pogrzebowych na światowym poziomie</strong>. Oferowane przez naszą firmę usługi są <strong>pełne godności, poszanowania wobec Państwa bliskich</strong> i troski o każdego z naszych Klientów.
                    </p>
                    <p className="phones text-center">
                    Jesteśmy do Państwa dyspozycji całą dobę, 7 dni w tygodniu pod numerami:<br/>
                    <a href="phone:+48627371040" title="Telefon stacjonarny">62 737-10-40</a> oraz <a href="phone:+48797126860" title="Telefon komórkowy">+48 797-126-860</a>
                    </p>
                    <p>
                    Aby <strong>sprostać oczekiwaniom Klientów</strong> oraz <strong>iść z duchem czasu</strong>, oferujemy profesjonalny estetyczny <strong>wystrój grobu</strong> oraz <strong>nagłośnienie</strong> z możliwością odtwarzania pieśni żałobnych podczas ostatniego pożegnania w kaplicy, w drodze na cmentarz czy też bezpośrednio na nim.
                    &nbsp;<strong>Wystrój grobu</strong> podczas ceremonii pogrzebowej jest to nieodłączny element każdej ceremonii pogrzebowej organizowanej przez naszą firmę.
                    &nbsp;<strong>Troszczymy się o każdy szczegół</strong> ceremonii pogrzebowej, aby była ona <strong>piękna i dostojna</strong>. W tak trudnych chwilach służymy Państwu pomocą w każdej sferze organizacji ceremonii pogrzebowej.
                    </p>
                    <p>
                    Chcąc doskonalić się w każdej ze świadczonych usług przykładamy szczególną wagę do doboru właściwego personelu naszej firmy. Wychodzimy z założenia, że to ludzie tworzą firmę i gwarantują jej wysoką pozycję. Rozumiemy jak ważne jest stałe podnoszenie kwalifikacji personelu i dlatego zapewniamy naszym pracownikom stałe uczestnictwo w kursach i szkoleniach, które wieńczone są przyznawaniem odpowiednich certyfikatów. 
                    </p>
                    <p>
                    Nasz zespół tworzą długoletni pracownicy którym stawiamy rygorystyczne wymagania, dzięki czemu są to osoby wyróżniające się rzetelnością, empatią, wysoką kulturą osobistą, nienaganna prezencją oraz ogromnym szacunkiem i poświęceniem w stosunku do wykonywanej pracy. Pracownicy są przygotowani na każdą sytuację oraz wyczuleni na to, by godnie i z szacunkiem traktować zmarłych i ich bliskich.
                    </p>
                </div>
            </div>
        </div>
    </HomeLayout>
  </>
)
export const pageQuery = graphql`
    query {
        logo: file(relativePath: { eq: "logo_poziome.png" }) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
    }
`

export default FirmaPage
